<template>
  <ImageText v-if="value.length" :value="value" theme="None" class="additional-info" />
</template>

<script>
import { mapState } from 'vuex'
import MediumZoom from 'medium-zoom'
import { GetCustomField } from '@/utils'

import ImageText from '@kvass/template-image-text'

export default {
  data() {
    return {
      zoomer: MediumZoom([], {
        scrollOffset: 20,
        background: 'rgba(255,255,255,0.8)',
      }),
    }
  },
  computed: {
    ...mapState('Project', ['item']),
    value() {
      let data = GetCustomField('additional-info', this.item.customFields) || []
      return data.map(item => {
        return {
          ...item,
          description: item.content,
        }
      })
    },
  },
  methods: {
    attach(el) {
      if (!el) this.detach()
      this.zoomer.attach(el || this.$el.querySelectorAll('img'))
    },
    detach() {
      if (!this.zoomer) return
      this.zoomer.detach()
    },
  },
  mounted() {
    this.attach()
  },
  beforeDestroy() {
    this.detach()
  },
  components: {
    ImageText,
  },
}
</script>

<style lang="scss">
.additional-info {
  column-gap: 6rem;

  .kvass-image-text {
    &__item {
      direction: rtl;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      padding: 2rem;

      &:nth-child(even) {
        direction: ltr;
        background-color: var(--secondary);
        background-image: url('https://assets.kvass.no/634fe0321d556172793cce11');
        background-size: 20%;
        background-repeat: repeat;
        background-blend-mode: color-dodge;

        @include respond-below('phone') {
          background-size: 60%;
        }

        .kvass-image-text__content {
          color: var(--secondary-contrast);
          text-align: right;
        }
      }

      &:first-child {
        padding-top: 3rem;
      }

      @include respond-below('phone') {
        display: flex;
        flex-direction: column-reverse;
        padding: $outerSpacing;
      }

      @include respond-above('phone') {
        padding-block: 6rem;
      }

      * {
        direction: ltr;
        text-align: left;
      }

      img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      max-width: 600px;
      padding: 4rem;

      @include respond-below('phone') {
        max-width: none;
        padding: 0;
      }
    }
  }
}
</style>
