<template>
  <Lead
    class="project-lead"
    :project="item.id"
    :reference="{ onModel: 'Project', ref: item.id }"
    :fields="['!contact.upsell', '!comment']"
  >
    <template #title>
      <p>Meld interesse</p>
      <small>Meld interesse her for mer informasjon om prosjektet</small>
    </template>
  </Lead>
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  computed: {
    ...mapState('Project', ['item']),
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.project-lead {
  max-width: 800px;
  margin: 0 auto;
  background-color: transparent !important;

  @include respond-below('phone') {
    padding: 1rem !important;
  }

  .lead__title {
    p {
      margin-block: 0;
    }

    small {
      font-weight: 400;
      font-size: 1rem;
      font-family: var(--primary-font);
    }
  }

  .lead__form {
    background-color: transparent;

    &-actions {
      justify-content: start;
    }
  }
}
</style>
