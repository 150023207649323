<template>
  <section v-if="valueComp" class="call-to-action" :class="`call-to-action--theme-${theme}`">
    <div class="call-to-action__content container" v-html="valueComp"></div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'default',
      validator(val) {
        return ['default', 'inverted'].includes(val)
      },
    },
  },
  computed: {
    ...mapState('Root', ['item']),
    valueComp() {
      return this.value || this.$path('item.customFields.call-to-action')
    },
  },
}
</script>

<style lang="scss">
.call-to-action {
  padding-block: 4rem;
  font-size: 1.2em;
  background-image: url('https://assets.kvass.no/634fe0321d556172793cce11');
  background-size: 20%;
  text-align: center;

  @include respond-below('phone') {
    background-size: 60%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--theme {
    &-default {
      background-color: var(--secondary);
      color: var(--secondary-contrast);
      background-blend-mode: color-dodge;
    }

    &-inverted {
      background-color: white;
      background-image: none;
    }
  }

  [data-cta='primary'] {
    &:hover {
      background-color: var(--secondary);
      color: var(--secondary-contrast);
    }
  }
}
</style>
