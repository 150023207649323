<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Hero :images="$path('media.cover', item)" class="section__hero" />
      <section class="section__additional-info">
        <ScrollAnchor value="additional-info" offset="-8rem" :delay="750" />
        <AdditionalInfo />
      </section>
      <CallToAction
        :theme="$path('customFields.additional-info.length', item) % 2 ? 'default' : 'inverted'"
      />
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { GetCustomField } from '@/utils'
import BrowserApiMixin from '@/mixins/browser-api'

import ScrollAnchor from '@kvass/scroll-anchor'
import ProjectComponents from '@/components/Project'
import Residentials from '@/components/Residentials'
import CallToAction from '@/components/CallToAction'
import Hero from '@/components/Hero'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),
    showFlatfinder() {
      return this.$path('item.flatfinders.length') && KvassConfig.get('stats.total')
    },
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.openhouses.length')) return true
      return !KvassConfig.get('siteSettings.hideNoOpenhouse')
    },
    showResidentialTemplates() {
      if (!this.item) return false
      return GetCustomField('residential-templates', this.item.customFields)
    },
    showResidentials() {
      return KvassConfig.get('stats.total') > 0
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    Residentials,
    ScrollAnchor,
    CallToAction,
    Hero,
  },
}
</script>

<style lang="scss">
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  .section {
    &--no-pb {
      padding-bottom: 0;
    }

    &--no-p {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__shop {
      min-height: 100vh;
    }

    &__lead {
      background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.05);
    }

    &__info {
      .container {
        display: grid;
        grid-gap: 6rem;
        grid-template-columns: repeat(2, 1fr);

        @include respond-below('phone') {
          grid-template-columns: 1fr;

          & > *:first-child {
            order: 2;
          }

          & > *:nth-child(2) {
            order: 1;
          }
        }
      }
    }

    &__additional-info {
      max-width: 2000px;
      margin-inline: auto;
    }
  }
}
</style>
