<template>
  <footer class="footer" :class="`footer--theme-${theme}`">
    <div class="footer__content">
      <FooterContent
        v-if="customFooterContent.length"
        :value="customFooterContent"
        class="footer__custom-content container"
      />
      <div class="footer__container container">
        <div>
          <img
            v-if="theme == 'default'"
            class="footer__logo"
            :src="logo"
            width="150"
            :alt="item.name"
          />
          <div class="footer__copyright">{{ year }} &copy; {{ item.name }}</div>
          <div class="footer__links">
            <router-link :to="`/api/legal/privacy/project/${item.id}`" target="_blank">
              {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
            </router-link>
            <a href="https://malling.no/forbehold" target="_blank">Forbehold</a>
            <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
          </div>
        </div>
        <so-me-footer v-if="someCustomField.length !== 0" :value="someCustomField" />
        <div class="footer__menu">
          <b class="footer__menu-title">{{ $t('menu') }}</b>
          <template v-for="(item, index) in navigation">
            <ActionComponent
              v-if="$path('action.name', item) !== $path('name', $route)"
              class="footer__menu-action"
              :class="{ 'footer__menu-action--empty': !item.action }"
              v-bind="item"
              :key="index"
            />
            <template v-if="item.items && item.items.length">
              <ActionComponent
                v-for="(nested, ni) in item.items"
                class="footer__menu-action footer__menu-action--indent"
                v-bind="nested"
                :key="index + '_' + ni"
              />
            </template>
          </template>
        </div>
        <img
          v-if="theme == 'compact'"
          class="footer__logo"
          :src="logo"
          width="200"
          :alt="item.name"
        />
      </div>
    </div>
    <div class="footer__bottom" :class="{ container: theme == 'compact' }">
      <div :class="{ container: theme == 'default' }">
        <span>
          Dette digitale prospektet er utviklet i samarbeid mellom Malling & Co Markets og gårdeier
          av {{ item.name }}
        </span>
        <span>
          {{ $t('pageMadeBy') }}
          <a :href="`https://www.kvass.no/?ref=${currentUrl}`" target="_blank">Kvass</a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'
import SoMeFooter from '@/components/SoMeFooter.vue'

export default {
  props: {
    navigation: Array,
    theme: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'compact'].includes(value)
      },
    },
    cookieManager: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    customFooterContent() {
      if (!this.item) return
      return GetCustomField('footer-content', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },
    logo() {
      //return logo based on primary-color lightness
      let backgroundLightness = getComputedStyle(document.documentElement).getPropertyValue(
        '--primary-l',
      )
      if (parseFloat(backgroundLightness) < 70) return API.logoInverted
      return API.logo
    },
    currentUrl() {
      return window.location.href
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
  },
  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  background-color: $brown;
  color: white;

  &__logo {
    margin-bottom: 2rem;
  }

  &__content {
    padding: 4rem 0;
  }

  &__bottom {
    padding-block: 0.5rem;

    & > div {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem;
  }

  &__copyright {
    a {
      font-weight: bold;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
  }

  &__menu {
    &-title {
      opacity: 0.5;
      margin-bottom: 1rem;
    }

    &-action {
      padding: 0;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;

      &--indent {
        margin-left: 0.5rem;
      }

      & + & {
        margin-top: 0.5rem;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &[href='https://www.kvass.no']
    {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        width: 100%;
        height: 2px;
        background-color: var(--primary);
      }
    }
  }

  p {
    margin-top: 0;
  }

  &__custom-content {
    margin-bottom: 2rem;

    a {
      text-decoration: underline;
    }
  }

  &__title {
    display: block;
    opacity: 0.5;
    margin-bottom: 1rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    gap: 0.5rem;
  }

  &__social-icons {
    a {
      margin-right: 0.8rem;
    }
  }

  .user-profile {
    display: none;
  }

  /* Themes */
  &--theme {
    &-compact {
      .footer__content {
        padding-block: 3rem;
      }

      .footer__bottom {
        padding-block: 1rem;
      }
    }
  }
}
</style>
