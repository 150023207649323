<template>
  <Residentials
    :filter="filter"
    :project="project"
    :properties="properties"
    :disable-navigation="true"
    :fields="['!price']"
  >
    <template #row-end="{ item }">
      <div class="residentials-actions">
        <a
          v-if="getFloorplan(item).url"
          class="residentials-actions__item"
          :href="getFloorplan(item).url"
          target="_blank"
        >
          <FontAwesomeIcon :icon="['fal', 'file-pdf']" size="lg" />
          Åpne plantegning
        </a>
      </div>
    </template>
  </Residentials>
</template>

<script>
import API from '@/api'
import Bus from '@/bus'

import Residentials from '@kvass/residentials/src/Component'

export default {
  data() {
    return {
      filter: {},
      project: API.project,
    }
  },
  computed: {
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  methods: {
    getFloorplan(item) {
      return this.$path('media.floorplan.0', item) || {}
    },
  },
  created() {
    Bus.on('residentials:filter', filter => (this.filter = filter))
  },
  components: {
    Residentials,
  },
}
</script>

<style lang="scss">
.residentials {
  --table-border: 1px solid #c0c0c0;

  tbody {
    tr:nth-child(odd) {
      background-color: transparent !important;
    }

    tr {
      border-bottom: var(--table-border);
    }
  }

  tr {
    th:nth-last-child(2),
    td:nth-last-child(2) {
      display: none;
    }

    th:last-child,
    td:last-child {
      width: 250px;
    }
  }

  thead {
    th {
      background-color: var(--secondary);
      padding: 1rem !important;
    }
  }

  &__scroller {
    background: none;
  }

  &__header {
    display: none;
  }

  &-actions {
    margin-left: auto;
    width: max-content;

    &__item {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: 10rem;
      border: 1px solid transparent;

      &:hover {
        border-color: var(--primary-contrast);
      }
    }
  }
}
</style>
