<template>
  <div class="hero">
    <div v-if="items.length" class="hero__slide">
      <Slider :value="items" :display-thumbnail="true" :manual-navigation="true" />
    </div>

    <div class="hero__content container">
      <div class="hero__slogan" v-html="description || item.description"></div>
      <div v-if="keyInfo.length && !hideStats" class="hero__stats">
        <template v-for="(item, index) in keyInfo">
          <div class="hero__stats-item">
            <FontAwesomeIcon
              v-if="index > 0"
              class="hero__stats-item-icon hero__stats-item-icon--left"
              :icon="['far', 'arrow-left']"
            />
            <span class="hero__stats-label" v-html="item"></span>
            <FontAwesomeIcon
              v-if="index < keyInfo.length - 1"
              class="hero__stats-item-icon hero__stats-item-icon--right"
              :icon="['far', 'arrow-right']"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ScrollIntoView } from '@/utils'
import Slider from '@kvass/media-render/Slider'

export default {
  props: {
    description: {
      type: String,
    },
    images: {
      type: Array,
      default: () => [],
    },
    hideStats: Boolean,
  },
  computed: {
    ...mapState('Root', ['item']),
    items() {
      return this.images
    },
    keyInfo() {
      return this.$path('item.customFields.key-info') || []
    },
  },
  methods: {
    gotoInfo() {
      ScrollIntoView('.section__info', -document.querySelector('.nav').clientHeight)
    },
    onScroll() {
      requestAnimationFrame(() => {
        const scrollTop = window.scrollY / 4
        document.body.style.setProperty('--parallax-top', `${scrollTop}px`)
      })
    },
  },
  metaInfo() {
    return {
      link: [...this.items.map(item => ({ rel: 'preload', as: 'image', href: item }))],
    }
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.hero {
  $height: 70vh;

  position: relative;
  height: $height;
  width: 100%;
  background-color: css-alpha('primary', 0.1);

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 500ms;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  &__content {
    position: absolute;
    inset: 0;

    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  &__slogan {
    height: 100%;
    width: 100%;
    color: white;
    text-shadow: 0 2px 4px rgba(black, 0.5);

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @include respond-below('phone') {
      font-size: 0.8rem;
    }

    & > * {
      max-width: 800px;
    }

    & > h2 {
      margin: 0;
      font-weight: 900;
      line-height: 1.1;
      font-size: 4em;
    }

    & > p {
      font-size: 1.25em;
    }
  }

  &__stats {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    background-color: var(--secondary);
    transform: translateY(1.5rem);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding: 0.5rem;

    @include respond-below('tablet') {
      justify-content: start;
    }

    &-item {
      position: relative;
      white-space: nowrap;
      padding: 1rem 1.5rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      scroll-snap-align: center;

      p {
        margin: 0;
      }

      &:not(:last-child) {
        border-right: 1px solid css-darken('secondary', 5%);
      }

      @include respond-below('tablet') {
        font-size: 0.9rem;
        min-width: 100vw;
        padding: 1rem;
        justify-content: center;
      }

      &-icon {
        position: absolute;
        opacity: 0.5;

        @include respond-above('tablet') {
          display: none;
        }

        &--right {
          right: 2rem;
        }

        &--left {
          left: 2rem;
        }
      }
    }
  }

  &__slide {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;

    iframe {
      min-height: $height;
    }

    .kvass-media-render-slider .kvass-media-render-image {
      top: var(--parallax-top, 0);
    }
  }
}
</style>
